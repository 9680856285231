import React, {useEffect, useState} from 'react';
import { useHistory } from 'react-router-dom';
import { useIsAuthenticated, useMsal } from '@azure/msal-react';
import CONSTANTS from '../../constants';
import NavUtils from '../../NavUtils';
import { useToast } from '../../components/ToastProvider';
import PMTCONSTANTS from '../../components/PMT/pmtConstants';
import utils from '../../utils';
import pmtutils from '../../components/PMT/pmtutils';
import DemandHealthApp from '../../components/DemandHealth/DemandHealthApp';


function DemandHealthAppPage({tenant, appToMatch}) {
    let [objectivesList, setObjectivesList] = useState();
    let [loading, setLoading] = useState(true);
    let [ apiKey, setAPIKey] = useState();
    let [groupMembers, setGroupMembers ] = useState([]);
    let [shouldLoadList, setShouldLoadList] = useState(true);

    let [appName, setAppName] = useState();
  
    const groupID = tenant.tenant.fields.aDGroupID;
    const { addToast } = useToast();
    const isAuthenticated = useIsAuthenticated();
    const history = useHistory();
    let hasAccess = NavUtils.hasAppAccess(tenant, CONSTANTS.APPS_BY_APPTYPE.MARKET_ACTIONS);
    const { instance, accounts } = useMsal();
    let userInfo = utils.findUserInfo(tenant.ourUserID, groupMembers)
  
    if(!hasAccess) {
        history.push('/');
    }
  
    const request = {
      scopes: ["User.Read"]
    };
      
    const getAPIKey = async(accToken) => {
      let response = await fetch(process.env.REACT_APP_GVMM_API_KEY_URL,{
        headers: {
          "Authorization": "Bearer " + accToken.accessToken
        }
      });
      const responsejson  = await response.json();
      setAPIKey(responsejson.value)
      return responsejson.value;
    }
  
    //Gets all list items
    const getPMTList = async (apiKey, app, inclDel = false) => {
      setLoading(true)
  
      let url = PMTCONSTANTS[app].GET_LIST;
  
      const result = await fetch(`${process.env.REACT_APP_DB_API_URL}${url}?include_deleted=${inclDel}`, {
        headers: {
          'Content-Type': 'application/json',
          'access_token': apiKey
          //need to add other access token for workspace access here
        }
      })
      if (result['status'] === 403) {
        alert("Invalid API key, access denied")
      }
  
      let resJson = await result.json()
      let alphabetizedList = pmtutils.formatDataBasedOnApp(resJson, 'OBJECTIVES')
      setObjectivesList(alphabetizedList)
      setLoading(false);
    }
  
    // Gets the PMT app data
    const getAppAltTitle = (ten, appName) => {

      let PMTtype = ten.tenant.fields.apps.filter((app) => {
  
        return app.fields.apptype === appName;
      })
      if(PMTtype && PMTtype.length > 0) {
        setAppName(PMTtype[0])
        return PMTtype[0]
      }
    }
  

  
    const getAccessToken = async (inst, account, req) => {
      try {
        let theAccToken = await inst.acquireTokenSilent({ ...req, account: account });
        return theAccToken;
      } catch (error) {
        console.log(error);
      }
    }
  
    const getMembersOfGroup = async (grpID) => {
  
      let accToken = await getAccessToken(instance, accounts[0], request);
      let response = await fetch(`${process.env.REACT_APP_WEB_API}/api/get-group-members?gid=${grpID}`, {
        headers: {
          "Authorization": "Bearer " + accToken.accessToken
        }
      });
  
      let jsonresponse = await response.json();
      setGroupMembers(jsonresponse);
    }
  
    useEffect(() => {
        try {
          (async () => {
            let app = getAppAltTitle(tenant, appToMatch);
            let token =  await getAccessToken(instance, accounts[0], request);
            let key = await getAPIKey(token);
            
            if(shouldLoadList === true) {
              await getPMTList(key, app.fields.appAlternateTitle, false);
              setShouldLoadList(false)
            }           
            await getMembersOfGroup(groupID, token)
            
            setLoading(false);
          })()
        } catch (err) {
          addToast({
            title: 'Update Error',
            body: 'Whoops!  something happened.  Please try again.'
          })
          setLoading(false);
        }
        //eslint-disable-next-line
      }, [isAuthenticated, groupID, shouldLoadList, tenant, apiKey])
  

  //Updates the list item and resets the parent state
  const updatedItem = (list, change) => {

    let retItem;
    let retArr = list.map((item) => {
      if(item.item_id === change.id) {
        let field = change.field;
        item[field] = change.value
        retItem = item;
      }
      return item;
    })
    setObjectivesList(retArr)
    return retItem;
  }
  
      // This call updates list when table cell is changed
  const updateListItemForItemChange = async(change) => {

    // Calls funtion that updates the list item and resets the parent list state returns the updated item
    let newItem = updatedItem(objectivesList, change)
    // reformatts to send to backend
    let listItem = pmtutils.sendToFormatter(appName, newItem, userInfo)
    let itemToSend = listItem && listItem[0] ? listItem[0] : listItem;
    let app = appName.fields.appAlternateTitle ? appName.fields.appAlternateTitle : appName.fields.apptype;
    
    await pmtutils.updateListItem(itemToSend, app, apiKey, addToast) 
    setShouldLoadList(true)
  }

  return (
    <div className='market-page-display'>
        <DemandHealthApp
          appName={appName}
          preview={ false } 
          tenantSetting={tenant}
          loading={ loading }
          objectivesList={objectivesList}
          apiKey={apiKey}
          memberList={groupMembers}
          appTitle='pmt_demand'
          updateListItemForItemChange={updateListItemForItemChange}
        />
    </div>
  )
}

export default DemandHealthAppPage
import React, {useEffect, useState} from 'react';
import { useHistory } from 'react-router-dom';
import { useIsAuthenticated, useMsal } from '@azure/msal-react';
import CONSTANTS from '../../constants';
import NavUtils from '../../NavUtils';
import PMTApp from '../../components/PMT/PMTApp';
import PMTformatUtils from '../../PMTformatUtils';
import { useToast } from '../../components/ToastProvider';
import PMTCONSTANTS from '../../components/PMT/pmtConstants';
import pmtutils from '../../components/PMT/pmtutils';
import utils from '../../utils';

function PMTAppPage({tenant, appToMatch}) {
  let [selectedListItems, setSelectedListItems] = useState([]);
  let [firstLoad, setFirstLoad] = useState(true)
  let [listOptions, setListOptions] = useState();
  let [parentPMTList, setParentPMTList] = useState();
  let [loading, setLoading] = useState(true);
  let [ apiKey, setAPIKey] = useState();
  let [groupMembers, setGroupMembers ] = useState([]);
  let [shouldLoadList, setShouldLoadList] = useState(true);
  let [showArchived, setShowArchived] =  useState(false);
  let [appName, setAppName] = useState();
  let [suppliers, setSuppliers] = useState();
  let [options, setOptions] = useState({
    'Show Archived': false,
    'Due in the next 6 months': false,
    'Due in the next year': false,
});

  const groupID = tenant.tenant.fields.aDGroupID;
  const { addToast } = useToast();
  const isAuthenticated = useIsAuthenticated();
  const history = useHistory();
  let hasAccess = NavUtils.hasAppAccess(tenant, CONSTANTS.APPS_BY_APPTYPE.MARKET_ACTIONS);
  const { instance, accounts } = useMsal();
  let userInfo = utils.findUserInfo(tenant.ourUserID, groupMembers)


  if(!hasAccess) {
      history.push('/');
  }

  const request = {
    scopes: ["User.Read"]
  };

  //Updates the list item and resets the parent state
  const updatedItem = (list, change) => {

    let retItem;
    let retArr = list.map((item) => {
      if(item.item_id === change.id) {
        let field = change.field;
        item[field] = change.value
        retItem = item;
      }
      return item;
    })
    setParentPMTList(retArr)
    return retItem;
  }

//Alphabetizes toplist
const alphabetizedList = (list) => {
    let tempArr = []
    let alphaList = list.sort((a, b) => a.list_name.localeCompare(b.list_name));
    setParentPMTList(alphaList)
    let listToFilter = alphaList[0].list_name;
    tempArr.push(listToFilter)
    if (firstLoad === true) {
      setSelectedListItems(tempArr)
      setFirstLoad(false);
    }
    return alphaList;
}

  // This call updates list when kanban card is moved Or when table view is changed
  const updateListItemForItemChange = async(change) => {

    // Calls funtion that updates the list item and resets the parent list state returns the updated item
    let newItem = updatedItem(parentPMTList, change)
    // reformatts to send to backend
    let listItem = pmtutils.sendToFormatter(appName, newItem, userInfo)
    let itemToSend = listItem && listItem[0] ? listItem[0] : listItem;
    let app = appName.fields.appAlternateTitle ? appName.fields.appAlternateTitle : appName.fields.apptype;
    
    await pmtutils.updateListItem(itemToSend, app, apiKey, addToast) 
    setShouldLoadList(true)
  }
    
  const getAPIKey = async(accToken) => {
    let response = await fetch(process.env.REACT_APP_GVMM_API_KEY_URL,{
      headers: {
        "Authorization": "Bearer " + accToken.accessToken
      }
    });
    const responsejson  = await response.json();
    setAPIKey(responsejson.value)
    return responsejson.value;
  }

  //Gets all list items
  const getPMTList = async (apiKey, app, inclDel = false) => {
    setLoading(true)

    let url = PMTCONSTANTS[app].GET_LIST;

    const result = await fetch(`${process.env.REACT_APP_DB_API_URL}${url}?include_deleted=${inclDel}`, {
      headers: {
        'Content-Type': 'application/json',
        'access_token': apiKey
        //need to add other access token for workspace access here
      }
    })
    if (result['status'] === 403) {
      alert("Invalid API key, access denied")
    }

    let resJson = await result.json()
    formatDataBasedOnApp(resJson, app)
    setLoading(false);
  }

  // Gets the PMT app data
  const getAppAltTitle = (ten, appName) => {
    let PMTtype = ten.tenant.fields.apps.filter((app) => {

      return app.fields.apptype === appName;
    })
    if(PMTtype && PMTtype.length > 0) {
      setAppName(PMTtype[0])
      return PMTtype[0]
    }
  }

  //Formats the data to custom_fields based on app
  const formatDataBasedOnApp = (list, app) => {
    if( list && list.length > 0 ) {
      let retArr; 
      //TODO: As we add more apps turn into switch statement
      switch (app) {
        case 'PMT MA':
          retArr = PMTformatUtils.formatMarketActions(list)
          alphabetizedList(retArr)
          let vals = getListNamesForDropdown(retArr)
          setListOptions(vals)
          return retArr;
        case 'Demand Health':
          retArr = PMTformatUtils.formatDemandHealth(list)
          alphabetizedList(retArr)
          return retArr;
        default:
          alphabetizedList(list)
      }
    }
  }

  const getAccessToken = async (inst, account, req) => {
    try {
      let theAccToken = await inst.acquireTokenSilent({ ...req, account: account });
      return theAccToken;
    } catch (error) {
      console.log(error);
    }
  }

  const getMembersOfGroup = async (grpID) => {

    let accToken = await getAccessToken(instance, accounts[0], request);
    let response = await fetch(`${process.env.REACT_APP_WEB_API}/api/get-group-members?gid=${grpID}`, {
      headers: {
        "Authorization": "Bearer " + accToken.accessToken
      }
    });

    let jsonresponse = await response.json();
    setGroupMembers(jsonresponse);
  }

  const updateTopList = (listItem) => {
    let copyList = [...parentPMTList]
    
    let updatedList = copyList.map((item) => {
      if(item.item_id === listItem.item_id) {
        item = listItem;
        return item;
      }
      return item;
    })
    setParentPMTList(updatedList)
  }

  useEffect(() => {
      try {
        (async () => {
          let app = getAppAltTitle(tenant, appToMatch);
          let token =  await getAccessToken(instance, accounts[0], request);
          let key = await getAPIKey(token);
          
          if(shouldLoadList === true) {
            await getPMTList(key, app.fields.appAlternateTitle, showArchived);
            setShouldLoadList(false)
          }
          let getSuppliers = await utils.getData('get_organization_names', apiKey)
          setSuppliers(getSuppliers ? getSuppliers : []);
         
          await getMembersOfGroup(groupID, token)
          
          setLoading(false);
        })()
      } catch (err) {
        addToast({
          title: 'Update Error',
          body: 'Whoops!  something happened.  Please try again.'
        })
        setLoading(false);
      }
      //eslint-disable-next-line
    }, [isAuthenticated, groupID, shouldLoadList, tenant, apiKey])

  // toggles archived and recalls the api based on if archived status
  const handleShowArchived = () => {
    setShowArchived(!showArchived);
    setShouldLoadList(true);
  }
  
  //tracks the checkbox dropdown events
  const displayOptionHandler = (e) => {
    let temp = { ...options };
    temp[e.target.id] = e.target.checked;
    setOptions(temp);

    if (e.target.value === 'Show Archived') {
        handleShowArchived();
    }
}

// resets the options, sets archived to false and recall API
const handleReload = () => {
  setOptions({
    'Show Archived': false,
    'Due in the next 6 months': false,
    'Due in the next year': false,
  })
  setShouldLoadList(true);
  setShowArchived(false);
}

const listOptionHandler = (e) => {
  let filteredVals = getListNamesForDropdown(parentPMTList, e)
  setListOptions(filteredVals)
}

const getListNamesForDropdown = (list, term=null) => {
  let retVals = list.map((item) => item.list_name)
  retVals = [...new Set(retVals)]

  if (term) {
      retVals = retVals.filter((item) => item.toLowerCase().includes(term.toLowerCase()))
  }
  return retVals;
}

  return (    
    <div className='market-page-display'>
        <PMTApp 
          appName={appName}
          tenantSetting={ tenant } 
          suppliers={suppliers}
          preview={ false } 
          loading={ loading }
          parentPMTList={parentPMTList}
          apiKey={apiKey}
          updateListItemForItemChange={updateListItemForItemChange}
          memberList={groupMembers}
          updateTopList={updateTopList}
          handleShowArchived={handleShowArchived}
          handleReload={handleReload}
          handleLoadList={() => setShouldLoadList(true)}
          archived={showArchived}
          displayOptionHandler={displayOptionHandler}
          options={options}
          listOptionHandler={listOptionHandler}
          listOptions={listOptions}
          selectionHandler={(val) => setSelectedListItems(val)}
          selectedListItems={selectedListItems}
          triggerLoading={() => setShouldLoadList(true)}
          appTitle={'pmt_ma'}
        />
    </div>
  )
}

export default PMTAppPage;
import React, { useState, useEffect } from 'react';
import { Spinner, Row, Col, Card } from 'react-bootstrap';
import CountryProfilePanel from './CountryProfilePanel';
import FieldDisplay from '../FieldDisplay';
import { Switch, Route, useRouteMatch, generatePath } from 'react-router-dom';

import AppSideNav from '../Layout/Nav/AppSideNav';
import CONSTANTS from '../../constants';
import utils from '../../utils'
import NavPageTitle from '../Layout/Nav/NavPageTItle';
import { Redirect } from 'react-router-dom';

function CountryHarness({ navDescr, tenant, selCountry, fullCountryList, contentFromRec }) {
    //this component provides routing level for country id and passes it to children

    const [ selectedCountry, setSelectedCountry ] = useState();
    const [ loading, setLoading ] = useState(false);

    let { path } = useRouteMatch();

    const KEEP_ITEMS_FOR_PBI_NAV = true;

    let useNavs = utils.cleanUpNavs(navDescr, contentFromRec, KEEP_ITEMS_FOR_PBI_NAV);

    const getDefaultPath = (forCountryISO, endUrl) => {
       return generatePath(`${path}/:isoCode/${endUrl}`, {isoCode: forCountryISO});
    }

    const getCountryFromListByISO = (iso, cntryList) => {
        return cntryList.find( (thisCntry) => {
            return thisCntry.ISO === iso;
        });
    }
    
    const getCountryByISO = async (iso) => {
        setLoading(true);
        setSelectedCountry(getCountryFromListByISO(selCountry, fullCountryList));

        setLoading(false);
    }


    //get country info for the selCountry
    useEffect( () => {
        //get the country info
        getCountryByISO(selCountry);
    // eslint-disable-next-line
    }, [selCountry]);


    return (
        loading ? 
            <Spinner animation="border" /> 
        :
            selectedCountry ?
                <>
                    <Row>
                        <Col>
                            <h1>{selectedCountry.country}</h1>
                        </Col>
                    </Row>
                    <Row>
                        <Col sm={12} md={3}>
                            <Card className="side-nav weird-corners app-sidenav">
                    
                                <AppSideNav
                                    apppath={`${CONSTANTS.APPS_BY_APPTYPE.IRC}`}
                                    tenantSettings={tenant}
                                    appname={CONSTANTS.APPS_BY_APPTYPE.IRC}
                                    item={contentFromRec}
                                    navs={useNavs} 
                                    subItems={true} 
                                    subUrlVal={selCountry}/>
                            </Card>
                        </Col>
                        <Col sm={12} md={9}>
                            <Switch>
                                <Route exact path={path}> 
                                    {/* if no trailing url, show the country-profile panel */}
                                    <Redirect
                                        to={getDefaultPath(selCountry, 'country-profile')} />
                                </Route>
                                <Route exact path={`${path}/country-profile`}>
                                    <NavPageTitle 
                                        navArray={useNavs} 
                                        infoID={''}
                                        overrideTitle="Country Profile" />
                                    <CountryProfilePanel 
                                        countryInfo={selectedCountry} 
                                        contentMaster={contentFromRec} />
                                </Route>
                                <Route path={`${path}/:infoID`}>
                                    {/* else, show the fieldDisplay panel */}
                                    <FieldDisplay
                                        tenant={tenant} 
                                        showGenPageTitle={true}
                                        cntryISO={selCountry} 
                                        fields={contentFromRec}
                                        fieldsDescr={navDescr} />
                                </Route>
                            </Switch>

                        </Col>
                    </Row>
                </>
                
            : null
    )
}

export default CountryHarness
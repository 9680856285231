import React, { useState } from 'react';
import FilterByPopover from '../MktActionsFilterBarComps/FilterByPopover';
import CONSTANTS from '../../constants';
import DropDownSelector from '../MktActionsFilterBarComps/DropDownSelector';
import Pager from '../MktActionsFilterBarComps/Pager';

function PMTListViewManipulator({ whichView, onChange, assigneeList, statusList, hmfCategories, onOptionsChange, disabled=false }) {

    const VIEWS_BY_TYPE = {};

    VIEWS_BY_TYPE[CONSTANTS.MARKET_ACTIONS_VIEWS.TABLE] = [ CONSTANTS.MARKET_ACTIONS_VIEW_OPTIONS.FILTER ]; //CONSTANTS.MARKET_ACTIONS_VIEW_OPTIONS.FILTER ]; //1
    VIEWS_BY_TYPE[CONSTANTS.MARKET_ACTIONS_VIEWS.KANBAN] = [ CONSTANTS.MARKET_ACTIONS_VIEW_OPTIONS.GROUPBY ]; //2
    VIEWS_BY_TYPE[CONSTANTS.MARKET_ACTIONS_VIEWS.GANTT] = [ CONSTANTS.MARKET_ACTIONS_VIEW_OPTIONS.PAGER ]; //3

    const [ curSelection, setCurSelection ] = useState(VIEWS_BY_TYPE[whichView][0].value ? VIEWS_BY_TYPE[whichView][0].value: ''); //default to table view

    const handleChange = (e) => {
        setCurSelection(e);
        onChange({view: whichView, value: e});
    }

    const getComponentBasedOnTypeAndlabel = (type, opt, indexOfList) => {
        if(type === 'dropdown') {
            return (
                <div>
                    
                <label className='dropdown-label display-block'>Grouped By</label>
                <DropDownSelector
                    className="toolbar-standard-dropdown"
                    indexOfList={indexOfList} 
                    disabled={disabled}
                    opt = {opt} 
                    key={indexOfList}
                    handleChange={handleChange} 
                    curSelection={curSelection} 
                />
                </div> )
                    
        }

        if(type === 'popup') {

            //based on label, pull the correct popover
            return (
                <div className='toolbar-standard-dropdown'>
                    <label className='dropdown-label display-block'>Filter By</label>
                    <FilterByPopover
                        className="dropdown-text-left"
                        opt={opt}
                        key={indexOfList}
                        handleChange={handleChange} 
                        assigneeList={assigneeList} 
                        statusList={ statusList } 
                        onChange={ onOptionsChange } 
                        hmfCategories={ hmfCategories }
                    />
                </div> )
        }

        if(type === 'pager') {
            return (
                <Pager 
                    onChange={onOptionsChange} /> )
        }
    }

    return (
       
        <>
            {
                VIEWS_BY_TYPE[whichView.toString()].map( (opt, idx) => {
                    return (getComponentBasedOnTypeAndlabel(opt.type, opt, idx));
                })  
            }
        </>

    )
}

export default PMTListViewManipulator
import React, { useState } from 'react';
import { Row, Col } from 'react-bootstrap';
import ListSkeletonLoader from '../Layout/ListSkeletonLoader';
import CONSTANTS from '../../constants';
import PMTTableView from './Table/PMTTableView';
import PMTKanbanView from './Kanban/PMTKanbanView';
import PMTCreateEditItemPopup from './PMTCreateEditItemPopup';
import PMTNoItemInList from './PMTNoItemInList';
import PMTCONSTANTS from './pmtConstants';
import utils from '../../utils';
import ModalDynamic from '../Layout/ModalDynamic';
import pmtutils from './pmtutils';
import { useToast } from '../../components/ToastProvider';
import PMTMarketActionsToolbar from './PMTtoolbar/PMTMarketActionsToolbar';


function PMTDisplay({ tenant, selectedListItems, triggerLoading, loading, listOptionHandler, selectionHandler, listOptions, memberList, suppliers, options, displayOptionHandler, apiKey, appName, handleReload, updateTopList, topList, handleListUpdate }) {

    const [showArchiveModal, setShowArchiveModal] = useState(false)
    let [show, setShow] = useState(false);
    let [itemID, setItemID] = useState();
    let [selectedArchive, setSelectedArchive] = useState([]);
    let [listViewOption, setListViewOption] = useState('2'); // '2' is Kanban
    let [sepByField, setSepByField] = useState();

    const { addToast } = useToast();
    let userInfo = utils.findUserInfo(tenant.ourUserID, memberList)

    let batchArchiveModal = {
        type: 'archive',
        title: utils.getTitle(`You’re about to archive ${selectedArchive.length} interventions`),
        body: `Archiving ${selectedArchive && selectedArchive.length > 1 ? `these ${selectedArchive.length} interventions` : 'this intervention'} 
        will remove ${selectedArchive && selectedArchive.length > 1 ? 'them' : 'it'} from all views. Are you sure you want to continue?`,
        app: 'market actions',
        subName: 'Archive',
    }

    
        //separates Kanban columns by fields
        const handleShowChange = (e) => {
            setSepByField(e.value);
        }
    
        // handles view change
        const listViewHandle = (e) => {
            setListViewOption(e);
        }

    //Filters out only the first list item on load or the listNames that are selected.
    const filterToListsSelected = (list, selected) => {
        let retArr;
        if (selected && selected.length > 0) {
            //filters to show testmarket only leave for trouble shooting
            let filteredArray = list.filter((listObj) => selected.includes((listObj.list_name)));
    
            //check if filters are applied if so display filtered data
            let listWithFilters = checkForFilters(filteredArray, options)
    
            if (listWithFilters && listWithFilters.length > 0) {
                retArr = listWithFilters;
            } else {
                retArr = filteredArray;
            }
        } else {
            retArr = [];
        }
        return retArr;
    }


    // Shows the modal for single item mode
    const handleShowListItemModal = (id = null) => {
        if (id) {
            setItemID(id)
        }
        if (show === true) {
            setItemID(null);
        }
        setShow(!show);
    }

    // filters for date range
    const filterInDateRange = (months, allListItems) => {
        let temp = allListItems
        let startDate = new Date()
        let startDateWithTimeStamp = startDate.getTime()
        let endDate = new Date().setMonth(startDate.getMonth() + months)

        let filteredList = temp.filter((listItem) => {
            let dueDate = new Date(listItem.due_date).getTime();
            return dueDate && dueDate >= startDateWithTimeStamp && dueDate <= endDate
        })
        return filteredList;
    }

    //Check if filters exist if so filter to selected items
    const checkForFilters = (tempArr, opts) => {
        let filteredList = [];

        if (opts['Show Archived'] === true) {
            filteredList = tempArr;
        }
        if (opts['Due in the next 6 months'] === true) {
            filteredList = filterInDateRange(6, tempArr)
        }
        if (opts['Due in the next year'] === true) {
            filteredList = filterInDateRange(12, tempArr)
        }
        return filteredList;
    }

    //sets the selected items to archive in state
    const handleArchiveSelection = (e) => {
        let id = e.target.name;
        let copyList = [...topList]// all interventions in all markets
    
        let listItem = copyList.filter((item) => item.item_id === id)
    
        if(selectedArchive && selectedArchive.length > 0 && (selectedArchive.findIndex(item => item.item_id === listItem[0].item_id) > -1)) {
            let filteredList = selectedArchive.filter((item) => item.item_id !== listItem[0].item_id)
            setSelectedArchive(filteredList);
        } else {
            setSelectedArchive([...selectedArchive, listItem[0]])
        }
    }

    // toggles archive, updates the list, resets selected state
    const toggleArchived = () => {
        let copyList = [...selectedArchive];
        let updatedList = copyList.map((item) => {
            let val = item.deleted === true ? false : true;
            item.deleted = val;
            return item;
        })

        handleBatchArchive(updatedList)
        setShowArchiveModal(false)
        setSelectedArchive([]);
    }

    //sends items to API to update
    const archiveData = async(item, app) => {
        await pmtutils.updateListItem(item, app, apiKey, addToast)
    }

    //formats data to what backend expects and loops through if multiple to send to backend
    const handleBatchArchive = (updatedList) => {
        let app = appName.fields.appAlternateTitle ? appName.fields.appAlternateTitle : appName.fields.apptype;
    
        updatedList.forEach((item) => {
            let listItem = pmtutils.sendToFormatter(appName, item, userInfo)
            let itemToSend = listItem && listItem[0] ? listItem[0] : listItem;
            archiveData(itemToSend, app)
        });
        // retriggers load
        triggerLoading();
      }
  

    return (
        !loading && topList && tenant ?
            <div>
                {
                    appName && appName.fields.appAlternateTitle === 'PMT MA' ?
                        <PMTMarketActionsToolbar
                            toggleArchive={() => selectedArchive && selectedArchive.length > 0 ? setShowArchiveModal(!showArchiveModal) : setShowArchiveModal(showArchiveModal)}
                            topList={topList}
                            listViewOption={listViewOption}
                            selectionHandler={selectionHandler}
                            handleShowChange={handleShowChange}
                            listViewHandle={listViewHandle}
                            listOptions={listOptions}
                            selectedListItems={selectedListItems}
                            appName={appName}
                            options={options}
                            filterToListsSelected={filterToListsSelected}
                            handleShowListItemModal={handleShowListItemModal}
                            displayOptionHandler={displayOptionHandler}
                            listOptionHandler={listOptionHandler}
                            memberList={memberList}
                        />
                    : ''
                }
                {
                    !loading ?
                        <Row>
                            <Col className={`view-display ${CONSTANTS.MARKET_ACTIONS_REVERSE_VIEWS[listViewOption]}`}>
                                {
                                    topList && topList.length > 0 ?
                                        listViewOption && listViewOption === CONSTANTS.MARKET_ACTIONS_VIEWS.KANBAN ?
                                            <PMTKanbanView
                                                listNames={selectedListItems}
                                                topList={filterToListsSelected(topList, selectedListItems)}
                                                onUpdate={handleListUpdate}
                                                separateByField={sepByField}
                                                tenant={tenant}
                                                appName={appName}
                                                assigneesToPass={memberList}
                                                handleShowListItemModal={handleShowListItemModal}
                                                handleArchiveSelection={handleArchiveSelection}
                                                selectedArchive={selectedArchive}
                                            />
                                        :
                                            <PMTTableView
                                                formattedList={filterToListsSelected(topList, selectedListItems)}
                                                suppliers={suppliers}
                                                appName={appName}
                                                handleShowItemModal={handleShowListItemModal}
                                                assigneesToPass={memberList}
                                                onUpdate={handleListUpdate}
                                                apiKey={apiKey}
                                                selectedArchive={selectedArchive}
                                            />
                                        :
                                        <PMTNoItemInList
                                            header={`There are no ${PMTCONSTANTS.LIST_ITEM_NAME[appName.fields.appAlternateTitle].toLowerCase()}s in this ${PMTCONSTANTS.LIST_NAME[appName.fields.appAlternateTitle].toLowerCase()}`}
                                            tenant={tenant}
                                            handleShowAddItemModal={handleShowListItemModal}
                                            body={`Add ${PMTCONSTANTS.LIST_ITEM_NAME[appName.fields.appAlternateTitle].toLowerCase()}s below, or go back to ${PMTCONSTANTS.APP_NAMES[appName.fields.appAlternateTitle]} home page`}
                                            dynamicItemName={'interventions'}
                                            dynamicAppName={tenant.tenant.tenantslug === 'Gavi MST' ? 'Roadmap Actions' : 'Market Actions'}
                                            appUrl='market_actions'
                                        />
                                }
                            </Col>
                        </Row>
                    :
                    <ListSkeletonLoader preview={false} />
                }
                {
                    show && show === true ?
                        <PMTCreateEditItemPopup
                            updateTopList={updateTopList}
                            tenant={tenant}
                            groupMembers={memberList}
                            show={show}
                            handleHideModal={() => setShow(false)}
                            listName={selectedListItems && selectedListItems.length > 0 ? filterToListsSelected(topList, selectedListItems)[0].list_name : null} 
                            itemID={itemID ? itemID : null}
                            mode={itemID && itemID !== null ? 'edit' : 'create'}
                            topList={topList}
                            appName={appName}
                            apiKey={apiKey}
                            clearItemID={() => setItemID()}
                            handleReload={handleReload}
                            suppliers={suppliers}
                            currentView={listViewOption}
                            listNames={listOptions} 
                        />
                        : null
                }
                {
                    selectedArchive && selectedArchive.length > 0 && showArchiveModal && showArchiveModal === true ?
                        <ModalDynamic 
                            classes={'archive-confirm-modal'}
                            id='archive-confirm-modal'
                            show={showArchiveModal}
                            content={batchArchiveModal}
                            tenant={tenant.tenant}
                            handleClose={() => setShowArchiveModal(false)}
                            animation={false}
                            submitRequest={toggleArchived}
                        />
                    : null
                }
            </div>
            :
            <ListSkeletonLoader preview={false} />
    )
}

export default PMTDisplay;
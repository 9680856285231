import React from 'react';
import { Dropdown, Row, Col, Button } from 'react-bootstrap';
import CheckBoxDropDownSelector from '../../MktActionsFilterBarComps/CheckBoxDropDownSelector';
import { ChevronDown, PlusCircle } from 'react-bootstrap-icons';
import PMTCONSTANTS from '../pmtConstants';
import PMTListViewManipulator from '../PMTListViewManipulator';
import PMTExcelExport from '../PMTExcelExport';
import BtnDynamic from '../../Layout/BtnDynamic';
import utils from '../../../utils';

function PMTMarketActionsToolbar({ topList, listViewOption, selectionHandler, handleShowChange, listViewHandle, listOptions, selectedListItems, appName, options, filterToListsSelected, toggleArchive, handleShowListItemModal, displayOptionHandler, listOptionHandler, memberList}) {
    
    let isDisabled = topList.length > 0 ? false : true;
  
    const listViews = [
        { name: 'Table', value: '1' },
        { name: 'Kanban', value: '2' },
    ]

    const listViewObj = {
        "1": "Table",
        "2": "Kanban",
    }

    //content for dynamic button
    const archiveBtnContent = {
        name: `Archive`,
        icon: utils.getIcon('archive'),
    }

    const getDropdownOption = (temp) => {
        const optionsArray = Object.keys(temp);
        let returnString = "";
        let filteredArray = optionsArray.filter((option) => {
            return temp[option] === true
        })
        if (filteredArray && filteredArray.length > 0) {
            for (let i = 0; i < filteredArray.length; i++) {
                if (i > 0) {
                    returnString = returnString + ", " + filteredArray[i].slice(0, 3) + "...";
                } else {
                    returnString = filteredArray[i];
                }
            }
        } else {
            returnString = 'Select...'
        }
        return returnString;
    }

    const handleSelectedListItems = (val, selected, fullList) => {
        let copySelected = [...selected]

        if(val.target.defaultValue === 'select-all') {
            //deselect all valuews
            if(JSON.stringify(copySelected) === JSON.stringify(fullList)) {
                selectionHandler([])
            //select all values    
            } else {
                selectionHandler(fullList)
            }
        } else {
            //check to see if value already exists if it does remove it
            if(copySelected.includes(val.target.defaultValue)) {
                copySelected = copySelected.filter((item) => item !== val.target.defaultValue)
            } else {
                // If item does not exist push it into the array
                copySelected.push(val.target.defaultValue)
            }
            selectionHandler(copySelected)
        }
    }

    // switch array to the object that the checkbox selector expects
    const switchToObjForm = list => {
        let retObj = {}
        list.forEach((key) => {
            retObj[key] = true
        })
        return retObj
    }
    
    const getOptionsObj = (list, selected) => {
        let retObj = {}
        if (list && list.length > 0 ) {
            list.forEach((key) => {
                retObj[key] = false;;
            })
        }

        if(selected && selected.length > 0) {
            selected.forEach((val) => {
                if (retObj.hasOwnProperty(val)) {
                    retObj[val] = true;
                }
            })
        }
        return retObj;
    }
    
        //gets a list of all status for filtering by dropdown
        const getStatusList = (listObj) => {
            if (listObj.hasOwnProperty('statuses')) {
                return listObj.statuses.map((listItem) => {
                    return listItem.status;
                });
            }
            return [];
        }

        return (
            <Row className="markets-toolbar-responsive">

                {/* Dropdown Container Holding All Dropdowns */}
                <Col className="dropdowns-container" lg={8}>

                    {/* View Dropdown */}
                    <div className="toolbar-dropdown m-i-end-03">
                        <label className="dropdown-label">View</label>
                        <Dropdown className="toolbar-standard-dropdown">
                            <Dropdown.Toggle className="select-toggle">
                                {listViewObj[listViewOption]}
                                <ChevronDown className="chevron-icon" />
                            </Dropdown.Toggle>
                            <Dropdown.Menu className="select-dropdown-menu">
                                {listViews.map((opt, idx) => (
                                    <Dropdown.Item
                                        key={idx}
                                        disabled={isDisabled}
                                        eventKey={idx}
                                        id={`opt${idx}`}
                                        name={opt.value}
                                        active={listViewOption === opt.value}
                                        onClick={(e) => listViewHandle(e.target.name)}
                                    >
                                        {opt.name}
                                    </Dropdown.Item>
                                ))}
                            </Dropdown.Menu>
                        </Dropdown>
                    </div>
    
                    {/* Markets Dropdown */}
                    <div className="toolbar-dropdown m-i-end-03">
                        <label className="dropdown-label">{PMTCONSTANTS.PMT_LIST_NAMES[appName.fields.appAlternateTitle]}</label>
                        <CheckBoxDropDownSelector
                            className="toolbar-standard-dropdown"
                            type="dropdown"
                            options={getOptionsObj(listOptions, selectedListItems)}
                            keepOpen={true}
                            displayOptionHandler={(e) => handleSelectedListItems(e, selectedListItems, listOptions)}
                            labelString={JSON.stringify(listOptions) === JSON.stringify(selectedListItems) ? `All ${PMTCONSTANTS.PMT_LIST_NAMES[appName.fields.appAlternateTitle].toLowerCase()} selected` : selectedListItems && selectedListItems.length > 1 ? 'Multiple selected' : selectedListItems && selectedListItems.length < 1 ? 'Select...' : selectedListItems[0]}
                            isSearchable={true}
                            selected={selectedListItems && selectedListItems.length > 0 ? switchToObjForm(selectedListItems) : {}}
                            handleSearchTerm={listOptionHandler}
                            selectAll={true}
                        />
                    </div>
    
                    {/* Grouped By Dropdown */}
                    <div className="toolbar-dropdown m-i-end-03">
                        <PMTListViewManipulator
                            className="toolbar-standard-dropdown"
                            disabled={isDisabled}
                            whichView={listViewOption}
                            type="dropdown"
                            onChange={handleShowChange}
                            assigneeList={memberList}
                            statusList={getStatusList(topList)}
                        />
                    </div>
    
                    {/* More Display Options Dropdown */}
                    <div className="toolbar-dropdown">
                        <label className="dropdown-label">More Display Options</label>
                        <CheckBoxDropDownSelector
                            className="toolbar-standard-dropdown"
                            type="dropdown"
                            options={options}
                            keepOpen={false}
                            displayOptionHandler={displayOptionHandler}
                            labelString={getDropdownOption(options)}
                        />
                    </div>
                </Col>

            {/* Buttons Container */}
            <Col className="buttons-container p-i justify-end">
                <PMTExcelExport
                    className="icon-btn btn-primary-lg"
                    disabled={isDisabled}
                    filteredMarkets={filterToListsSelected(topList, selectedListItems)}
                />
                <BtnDynamic
                    classes="icon-btn surface-brand-primary btn-primary-lg m-i-start-03"
                    content={archiveBtnContent}
                    actionHandler={toggleArchive}
                />
                <Button className="icon-btn surface-brand-primary btn-primary-lg m-i-start-03" onClick={handleShowListItemModal}>
                    <PlusCircle />
                    Add
                </Button>
            </Col>
        </Row>
    );
}

export default PMTMarketActionsToolbar
import React, {useState} from 'react';
import { Row, Col, } from 'react-bootstrap';
import DemandHealthToolbar from './DemandHealthToolbar';
import PMTTable from '../PMT/Table/PMTTable';

function DemandHealthDisplay({ topList, appName, configType, tableHeaderConfig, handleListUpdate, apiKey, header=null, subheader=null}) {
  const [ searchTerm, setSearchTerm ] = useState('');

  
  const filterToSearchTerm = (list, term) => {
    if (term && term !== '') {
      let retList = list.filter((item) => {
        return item.list_name.toLowerCase().includes(term.toLowerCase())
      })
      return retList;
    } else {
      return list;
    }
  }

  return (
    <div>
      <p className='body-large'>{header}</p>
      <p className='body-small'>{subheader}</p>
      <Row>
        <DemandHealthToolbar 
          searchTermHandler={(e) => setSearchTerm(e)}
        />
      </Row>
      {
        <Row>
          <Col className={`view-display`}>
            {
              topList && topList.length > 0 ?
                <PMTTable 
                  tableHeaderConfig={tableHeaderConfig}
                  formattedList={filterToSearchTerm(topList, searchTerm)}
                  appName={appName.fields.appAlternateTitle}
                  handleListUpdate={handleListUpdate}
                  apiKey={apiKey}
                  configType={configType}
                />
                : ''
            }
          </Col>
        </Row>
      }
    </div>
  )
}

export default DemandHealthDisplay
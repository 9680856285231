import React from 'react';
//dependency imports
import { Image, Tabs, Tab } from 'react-bootstrap';

//file imports
import BreadCrumbBar from '../Layout/Nav/BreadCrumbBar';
import marketActionsPreviewImage from '../../assets/market-actions-cover.png';
import utils from '../../utils';
import CONSTANTS from '../../constants';
import MarketActionsSummaryView from '../PMT/PMTChartDashboard/MarketActionsSummaryView';
import ListSkeletonLoader from '../Layout/ListSkeletonLoader';
import DemandHealthDisplay from './DemandHealthDisplay';
import PMTCONSTANTS from '../PMT/pmtConstants';
// import { useToast } from '../../components/ToastProvider';
import PMTtableConfig from '../PMT/Table/PMTtableConfig';
import demandHealthByCountry from '../../assets/demandHealthByCountry.png';


function DemandHealthApp({ objectivesList, tenantSetting, appTitle=null,  preview=false, apiKey, appName=null,updateListItemForItemChange, loading=false, memberList }) {
    
    // let [ setActivitiesList ] = useState();
    // let [ shouldLoadList, setShouldLoadList ] = useState(true);

    // const { addToast } = useToast();

    let breadCrumbName = utils.findURLPath(tenantSetting.tenant.fields.apps, appTitle)

    // const getPMTList = async (apiKey, app, inclDel = false) => {
    //     let url = PMTCONSTANTS[app.fields.appAlternateTitle].GET_ACTIVITIES;
    
    //     const result = await fetch(`${process.env.REACT_APP_DB_API_URL}${url}?include_deleted=${inclDel}`, {
    //       headers: {
    //         'Content-Type': 'application/json',
    //         'access_token': apiKey
    //         //need to add other access token for workspace access here
    //       }
    //     })
    //     if (result['status'] === 403) {
    //       alert("Invalid API key, access denied")
    //     }
    
    //     let resJson = await result.json()
    //     setActivitiesList(resJson)
    //   }

    //   useEffect(() => {
    //     try {
    //       (async () => {
            
    //         if(shouldLoadList === true && appName && apiKey) {
    //           await getPMTList(apiKey, appName, false);
    //           setShouldLoadList(false)
    //         }           
    //       })()
    //     } catch (err) {
    //       addToast({
    //         title: 'Update Error',
    //         body: 'Whoops!  something happened.  Please try again.'
    //       })
    //     }
    //     //eslint-disable-next-line
    //   }, [shouldLoadList, apiKey, appName])

      const getTableconfig = (tableType, app, list, updateListItemForItemChange, key) => {
        return PMTtableConfig[tableType](app, list[0], updateListItemForItemChange, key)
      }

    return (
        <div className="market-actions-display">
            {
                !preview ?
                    <>
                        <BreadCrumbBar
                            appName={breadCrumbName}
                        />
                        <h2 className='m-b-end-08'>{CONSTANTS.APPS_BY_NAME[breadCrumbName.toUpperCase()]}</h2>
                        <Tabs
                            defaultActiveKey={'country-profiles'}
                            id='pmt-user-tabs'
                            className='app-tabs m-b-end-08'
                        >
                            <Tab
                                eventKey={'country-profiles'}
                                title='Country Profiles'
                            >
                                {
                                    !loading  && tenantSetting ? 
                                        <MarketActionsSummaryView 
                                            appName={appName.fields.appAlternateTitle}
                                            reportID={PMTCONSTANTS[appName.fields.appAlternateTitle].DASH_PBI_COUNTRIES.reportID}
                                            datasetID={PMTCONSTANTS[appName.fields.appAlternateTitle].DASH_PBI_COUNTRIES.datasetID}
                                        />
                                    : <ListSkeletonLoader preview={false} />
                                }
                            </Tab>
                            <Tab
                                eventKey={'antigen-profiles'}
                                title='Antigen Profiles'
                            >
                                {
                                    !loading  && tenantSetting ? 
                                        <MarketActionsSummaryView 
                                            appName={appName.fields.appAlternateTitle}
                                            reportID={PMTCONSTANTS[appName.fields.appAlternateTitle].DASH_PBI_ANTIGEN.reportID}
                                            datasetID={PMTCONSTANTS[appName.fields.appAlternateTitle].DASH_PBI_ANTIGEN.datasetID}
                                        />
                                    : <ListSkeletonLoader preview={false} />
                                }
                            </Tab>
                            <Tab
                                eventKey={'objective'}
                                title='Objectives'
                            >
                                {
                                    objectivesList && objectivesList.length > 0 ?
                                        <DemandHealthDisplay
                                            tenant={tenantSetting}
                                            memberList={memberList}
                                            apiKey={apiKey}
                                            appName={appName}
                                            tableHeaderConfig={getTableconfig('OBJECTIVES', appName, objectivesList, updateListItemForItemChange, apiKey)}
                                            topList={objectivesList}
                                            loading={loading}
                                            configType={'OBJECTIVES'}
                                            handleListUpdate={updateListItemForItemChange}
                                        />
                                    : <ListSkeletonLoader preview={false} />
                                }
                            </Tab>
                            <Tab
                                eventKey={'country-activities'}
                                title='Country Activities'
                            >
                                {
                                    // activitiesList && activitiesList.length > 0 ?
                                    <Image src={demandHealthByCountry} responsive/>
                                        // <DemandHealthDisplay
                                        //     tenant={tenantSetting}
                                        //     memberList={memberList}
                                        //     apiKey={apiKey}
                                        //     appName={appName}
                                        //     topList={activitiesList}
                                        //     tableHeaderConfig={getTableconfig('ACTIVITIES', appName, activitiesList, updateListItemForItemChange, apiKey)}
                                        //     loading={loading}
                                        //     configType={'ACTIVITIES'}
                                        //     handleListUpdate={updateListItemForItemChange}
                                        //     header={'Demand health by antigen'}
                                        //     subheader={'Objectives and target outcomes for 2023-2025'}
                                        // />
                                    // : <ListSkeletonLoader preview={false} />
                                }
                            </Tab>
                        </Tabs>
                    </>
                    :
                    <div className='preview-img-div'>
                        <Image className='preview-card-img' src={appName && appName.fields && appName.fields.previewImage && appName.fields.previewImage.url ? appName.fields.previewImage.url : marketActionsPreviewImage} />
                    </div>
            }
        </div>
    )
}

export default DemandHealthApp
import React, { useState, useEffect } from 'react'

import { useIsAuthenticated } from '@azure/msal-react';
import { useToast } from '../ToastProvider';
import utils from '../../utils';
import ModalDynamic from '../Layout/ModalDynamic';
import ListItemViewModal from './ListItemViewModal';
import PMTformatUtils from '../../PMTformatUtils';
import PMTCONSTANTS from './pmtConstants';
import pmtutils from './pmtutils';

function PMTCreateEditItemPopup({tenant, listNames, suppliers, show=false, apiKey, updateTopList, appName, topList, groupMembers, clearItemID, handleHideModal, itemID=null, handleReload, listName=null, mode='create', currentView='2'}) {

    const isAuthenticated = useIsAuthenticated();
    const { addToast } = useToast();
  
    const [loading, setLoading] = useState(true);
    const [firstLoad, setFirstLoad] = useState(true);
    const [form, setForm] = useState({ list_name: listName });
    const [ formReadyForSubmit, setFormReadyForSubmit ] = useState(false);
    const [inputLoading, setInputLoading] = useState(false);
    const [errors, setErrors] = useState({});
    const [comments, setComments] = useState('');
    const [showArchiveModal, setShowArchiveModal] = useState(false)

    let featureflags = utils.getFeatureflags(process.env.REACT_APP_ENV, 'filter-lb-out-market-actions', tenant.tenant.fields.featureflags);
    let userInfo = utils.findUserInfo(tenant.ourUserID, groupMembers)
    
    //filters the list to find correct item_id and sets the form
    const filterListItem = (id, list) => {
   
      let listItem = list.filter((item) => {
        return item.item_id === id;
      })
      setForm(listItem[0])
    }

    useEffect(() => {
      //initially sets comment values from the original form
      if (firstLoad === true && form && form.comments) {
        setComments(form.comments)
        setFirstLoad(false);
      }
    },[form, firstLoad])
  
    // cancels edit or create and clears the form 
    const handleAbort = () => {
      setForm({ listID: listName });
      setForm();
      handleHideModal();
      clearItemID()
    }
  
    const toggleArchived = () => {
      //copying state object
      let cpyState = {...form};
  
      //modify cpyState to set archived to value
      cpyState['deleted'] = !!cpyState.deleted === true ? false : true
      //set back into state
      setForm(cpyState);
      setFormReadyForSubmit(true);
    }

    

    //gets a list of available assignees for the assignee field
    const assigneesToPass = utils.setMembersListBasedOnMarketName(groupMembers, [], '', featureflags.FILTER_LB_OUT_MARKET_ACTIONS, tenant.tenant);
  
    // //validates form to confirm all required fields are filled in
    const validateForm = (formToCheck) => {
      const { item_name } = formToCheck;
      let newErrors = {};

      if (!item_name || item_name === '') {
        newErrors.item_name = 'Task Name is Required';
      }
      return newErrors;
    }
  
  //on field change other than comments updates form
  const onChange = (field, value) => {
    if(field === 'customField_3') {
      //remove duplicate values
      let uniqueSelections = value.filter((item, index, self) => {
        return index === self.findIndex((t) => t.hmf_category === item.hmf_category)
      })
      uniqueSelections = pmtutils.getInitialLabelVals(uniqueSelections)
      setForm({
        ...form,
        [field]: value === 'none' ? '' : uniqueSelections
      });
    } else {
      setForm({
        ...form,
        [field]: value === 'none' ? '' : value
      });
    }
  };

  // adds new comments to form and formats form for backend submission
  const updateFormWithComments = (itemComments) => {
    let itemCopy = {...form};

    itemCopy.comments = itemComments;
    //leaving for now - easily clears comments
    // itemCopy.comments = []
    setForm(itemCopy)

    let formatedCopy = PMTformatUtils.deformatMarketActions(itemCopy)
    return {formToSend: formatedCopy, listItemToUpdate: itemCopy};
  }

    //Submits comments to the API
    const submitListItemComment = async () => {
        setInputLoading(true);
        let itemToUpdate = updateFormWithComments(comments);

      try {
        const result = await fetch(`${process.env.REACT_APP_DB_API_URL}${PMTCONSTANTS[appName.fields.appAlternateTitle].UPSERT_ITEM}?${PMTCONSTANTS[appName.fields.appAlternateTitle].ID_PARAM}=${itemToUpdate.item_id}`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'access_token': apiKey
          },
          body: JSON.stringify(itemToUpdate.formToSend)
        })
        updateTopList(itemToUpdate.listItemToUpdate)
        setInputLoading(false);
        if (result['status'] === 403) {
          addToast({
            title: 'isValid Key',
            body: "Invalid API key, access denied"})
        }
      } catch (error) {
        console.log('error ', error)
        if(error) {
          addToast({
            title: 'Update Error',
            body: 'Whoops!  something happened on this submit.  Please try again.'
          })
        }
      }
    }
  
    //updates a comment in state
    const updateListItemComment = (commentObj) => {
      let copyComList = [...comments];

      //checks if there is a comment obj without a commentID
      if (copyComList.some(comment => !comment.comment_id)) {
        // if obj exists it updates the comment
        copyComList = comments.map((comment) => {
          if(!comment.comment_id) {
            comment = commentObj;
            return comment;
          }
          return comment;
        })
        //if it doesn't exists it pushes the new comment obj into the comments state array
      } else {
        copyComList.push(commentObj)
      }
      // //set new comment obj in state
      setComments(copyComList);
    }

    const submitForm = async () => {
      let app = appName.fields.appAlternateTitle;
      let itemToSend = pmtutils.sendToFormatter(appName, form, userInfo)
      let params = form.item_id ? `?${PMTCONSTANTS[app].ID_PARAM}=${form.item_id ? form.item_id : ''}` : ''
  
      try {
        const result = await fetch(`${process.env.REACT_APP_DB_API_URL}${PMTCONSTANTS[app].UPSERT_ITEM}${params}`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'access_token': apiKey
          },
          body: JSON.stringify(itemToSend)
        })
        if (result['status'] === 403) {
          addToast({
            title: 'isValid Key',
            body: "Invalid API key, access denied"})
        }
      } catch (error) {
        addToast({
          title: 'Update Error',
          body: 'Whoops!  something happened on this submit.  Please try again.'
        })
      }
    }
  
    const onSubmit = (e) => {
      e.preventDefault();

      let copy = {...form};
      const errorsOnForm = validateForm(copy);

      if (Object.keys(errorsOnForm).length < 1) {
        setForm(copy);
        setFormReadyForSubmit(true);
        setErrors(errorsOnForm)
      } else {
        setErrors(errorsOnForm);
      }
    }
  
    useEffect(() => {
      if (isAuthenticated) {
        try {
          (async () => {
            setLoading(true);
            if(formReadyForSubmit && formReadyForSubmit === true) {
                handleAbort()
                await submitForm();
                setFormReadyForSubmit(false);
                await handleReload(null, currentView);
            }
            if(mode === 'edit') {
              filterListItem(itemID, topList);
            }
            setLoading(false);
          }
          )()
        } catch (err) {
          console.log(err);
          setLoading(false);
        }
      }
    //eslint-disable-next-line  
    }, [isAuthenticated, itemID, topList, groupMembers, formReadyForSubmit])
       
      const clearCommentInput = () => {
        document.getElementById('comment_value').value = ''
      } 

    
    return (
      groupMembers && assigneesToPass && tenant ?
        <>
          <ListItemViewModal
            show={show}
            tenant={tenant}
            handleClose={handleAbort}
            onSubmit={onSubmit}
            groupMembers={groupMembers}
            errors={errors}
            assigneesToPass={assigneesToPass}
            apiKey={apiKey}
            suppliers={suppliers}
            itemID={itemID}
            comments={comments}
            submitListItemComment={submitListItemComment}
            updateListItemComment={updateListItemComment}
            clearCommentInput={clearCommentInput}
            handleArchived={() => setShowArchiveModal(!showArchiveModal)}
            appName={appName}
            inputLoading={inputLoading}
            onChange={onChange}
            loading={loading}
            mode={mode}
            form={form}
            listNames={listNames} 
          />
        : 
        {/* Modal that popsup to confirm Achive action */}
          <ModalDynamic
            classes={'archive-confirm-modal'}
            id='archive-confirm-modal'
            show={showArchiveModal}
            content={PMTCONSTANTS[appName.fields.appAlternateTitle].ARCHIVE_MODAL}
            tenant={tenant.tenant}
            handleClose={handleAbort}
            animation={false}
            submitRequest={toggleArchived} />
        </> 
      : null
    )
}

export default PMTCreateEditItemPopup
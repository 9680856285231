import React from 'react';
//dependency imports
import { Image, Tabs, Tab } from 'react-bootstrap';

//file imports
import BreadCrumbBar from '../Layout/Nav/BreadCrumbBar';
import marketActionsPreviewImage from '../../assets/market-actions-cover.png';
import PMTDisplay from './PMTDisplay';
import utils from '../../utils';
import CONSTANTS from '../../constants';
import MarketActionsSummaryView from './PMTChartDashboard/MarketActionsSummaryView';
import ListSkeletonLoader from '../Layout/ListSkeletonLoader';
import PMTCONSTANTS from './pmtConstants';
import DemandHealthDisplay from '../DemandHealth/DemandHealthDisplay';



function PMTApp({ listOptions, selectionHandler, selectedListItems, tenantSetting, appTitle=null, suppliers, triggerLoading, listOptionHandler, handleShowArchived, handleLoadList, handleReload, preview=false, apiKey, updateTopList, appName=null, archived, options, displayOptionHandler, parentPMTList, updateListItemForItemChange, loading=false, memberList }) {
    let breadCrumbName = utils.findURLPath(tenantSetting.tenant.fields.apps, appTitle)

    const handleLoadArchived = (archived = null, setView = 1) => {
        handleShowArchived(true)
        handleLoadList();
    }
    return (
        <div className="market-actions-display">
            {
                !preview ?
                    <>
                        <BreadCrumbBar
                            appName={breadCrumbName}
                        />
                        <h2 className='m-b-end-08'>{CONSTANTS.APPS_BY_NAME[breadCrumbName.toUpperCase()]}</h2>
                        <Tabs
                            defaultActiveKey={'summary'}
                            id='pmt-user-tabs'
                            className='app-tabs m-b-end-08'
                        >
                            <Tab
                                eventKey={'summary'}
                                title='Summary'
                            >
                                {
                                    !loading  && tenantSetting ? 
                                        <MarketActionsSummaryView 
                                            appName={appName.fields.appAlternateTitle}
                                            reportID={PMTCONSTANTS[appName.fields.appAlternateTitle].DASH_PBI.reportID}
                                            datasetID={PMTCONSTANTS[appName.fields.appAlternateTitle].DASH_PBI.datasetID}
                                            
                                        />
                                    : <ListSkeletonLoader preview={false} />
                                }
                            </Tab>
                            <Tab
                                eventKey={'detailed-view'}
                                title='Detailed View'
                            >
                                {
                                    parentPMTList && parentPMTList.length > 0 ?
                                        PMTCONSTANTS.APP_NAMES[appName.fields.appAlternateTitle] === 'Market Actions' ?
                                            <PMTDisplay 
                                                tenant={tenantSetting}
                                                memberList={memberList}
                                                suppliers={suppliers}
                                                options={options}
                                                displayOptionHandler={displayOptionHandler}
                                                apiKey={apiKey}
                                                appName={appName}
                                                handleReload={handleReload}
                                                updateTopList={updateTopList}
                                                topList={parentPMTList}
                                                loading={loading}
                                                handleListUpdate={updateListItemForItemChange}
                                                handleLoadArchived={handleLoadArchived}
                                                listOptionHandler={listOptionHandler}
                                                listOptions={listOptions}
                                                selectionHandler={selectionHandler}
                                                selectedListItems={selectedListItems}
                                                triggerLoading={triggerLoading}
                                            />
                                        : 
                                        PMTCONSTANTS.APP_NAMES[appName.fields.appAlternateTitle] === 'Demand Health' ?
                                            <DemandHealthDisplay
                                                tenant={tenantSetting}
                                                memberList={memberList}
                                                apiKey={apiKey}
                                                appName={appName}
                                                topList={parentPMTList}
                                                loading={loading}
                                                handleListUpdate={updateListItemForItemChange}
                                            />
                                        : ''
                                    : <ListSkeletonLoader preview={false} />
                                }
                            </Tab>
                        </Tabs>
                    </>
                    :
                    <div className='preview-img-div'>
                        <Image className='preview-card-img' src={appName && appName.fields && appName.fields.previewImage && appName.fields.previewImage.url ? appName.fields.previewImage.url : marketActionsPreviewImage} />
                    </div>
            }
        </div>
    )
}

export default PMTApp;
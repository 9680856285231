import ReactTblSelectEditCell from "../../react-tbl-editable-cells/ReactTblSelectEditCell";
import PMTCONSTANTS from "../pmtConstants";
import ReactTblTextAreaEditCell from "../../react-tbl-editable-cells/ReactTblTextAreaEditCell";
import TableCellDropDown from "./TableCellDropDown";
import TableDatePicerCell from "./TableDatePickerCell";
import EnumSelector from "../../Alchemy/EnumSelector";


const PMTtableConfig = {
  getMarketActionsTableConfig: (appName, handleShowModal, updatedData, organization, assigneesToPass, priorities, tenant, key) => {
    //gets a list of available assignees for the assignee field
    const assigneesList = assigneesToPass.map((assignee) => {
      return { label: assignee.mail, value: assignee.mail };
    })

    return [
      {
        Header: PMTCONSTANTS[appName].CUSTOM_KEYS.customField_1,
        accessor: rowObj => {
          return (
            <ReactTblTextAreaEditCell
              initialValue={rowObj.customField_1}
              rowID={rowObj.item_id}
              column={'customField_1'}
              showLinkIcon={false}
              updateMyData={updatedData}
            />
          )
        },
      },
      {
        Header: PMTCONSTANTS[appName].CUSTOM_KEYS.customField_2,
        accessor: rowObj => {
          return (
            <ReactTblTextAreaEditCell
              initialValue={rowObj.customField_2}
              handleShowIntModal={handleShowModal}
              rowID={rowObj.item_id}
              column={'customField_2'}
              showLinkIcon={false}
              updateMyData={updatedData}
            />
          )
        },
      },
      {
        Header: PMTCONSTANTS.LIST_ITEM_NAME[appName],
        accessor: rowObj => {
          return (
            <ReactTblTextAreaEditCell
              initialValue={rowObj.item_name}
              handleShowIntModal={handleShowModal}
              rowID={rowObj.item_id}
              column={'item_name'}
              showLinkIcon={true}
              handleShowItemModal={handleShowModal}
              updateMyData={updatedData}
            />
          )
        },
        className: 'intervention-col',
      },
      {
        Header: PMTCONSTANTS[appName].CUSTOM_KEYS.customField_3,
        accessor: rowObj => {

          return (
            <EnumSelector
              className='hmfCategory'
              enum_type={PMTCONSTANTS.PMT_ENUMS[appName].customField_3}
              apiKey={key}
              isMulti={true}
              disableCreate={false}
              value={rowObj.customField_3 ? rowObj.customField_3 : []}
              isClearable={true}
              fieldName={PMTCONSTANTS[appName].CUSTOM_FIELD_NAME.customField_3}
              onChange={(e) => updatedData(rowObj.item_id, 'customField_3', e)}
            />
          )
        },
        className: 'hmfCategory',
      },
      {
        Header: PMTCONSTANTS[appName].CUSTOM_KEYS.customField_4,
        accessor: rowObj => {
          return (
            <ReactTblSelectEditCell
              initialValue={rowObj.customField_4}
              row={rowObj.item_id}
              column={'customField_4'}
              updateMyData={updatedData}
              selectionValues={organization}
              forName='vaccine'
            />
          )
        },
      },
      {
        Header: 'Assignee',
        accessor: rowObj => {
          return (
            <TableCellDropDown
              initialValue={rowObj.assigned_to}
              rowID={rowObj.item_id}
              column={'assignedTo'}
              updateMyData={updatedData}
              selectionValues={assigneesList}
              forName='assignedTo'
              customField={true} />
          )
        },
      },
      {
        Header: PMTCONSTANTS[appName].CUSTOM_KEYS.customField_5,
        accessor: rowObj => {
          return (
            <ReactTblTextAreaEditCell
              initialValue={rowObj.customField_5}
              handleShowIntModal={handleShowModal}
              rowID={rowObj.item_id}
              column={'customField_5'}
              showLinkIcon={false}
              updateMyData={updatedData}
            />
          )
        },
      },
      {
        Header: PMTCONSTANTS[appName].CUSTOM_KEYS.customField_6,
        accessor: rowObj => {
          return (
            <ReactTblTextAreaEditCell
              initialValue={rowObj.customField_6}
              handleShowIntModal={handleShowModal}
              rowID={rowObj.item_id}
              column={'customField_6'}
              showLinkIcon={false}
              updateMyData={updatedData}
            />
          )
        },
      },
      {
        Header: 'Priority',
        accessor: rowObj => {
          return (
            <TableCellDropDown
              initialValue={rowObj.priority}
              rowID={rowObj.item_id}
              column={'priority'}
              updateMyData={updatedData}
              selectionValues={priorities}
              forName='priority'
              customField={true} />
          )
        },
      }, {
        Header: 'Due Date',
        accessor: rowObj => {
          return (
            <TableDatePicerCell
              initialValue={rowObj.due_date}
              row={rowObj.item_id}
              column={'due_date'}
              updateMyData={updatedData}
            />
          )
        }
      },
      {
        Header: PMTCONSTANTS[appName].CUSTOM_KEYS.customField_7,
        accessor: rowObj => {
          return (
            <ReactTblSelectEditCell
              initialValue={rowObj.customField_7}
              row={rowObj.item_id}
              column={'customField_7'}
              updateMyData={updatedData}
              selectionValues={PMTCONSTANTS[appName].CUSTOM_FIELD_7_SELECT}
              forName='dueDateTypes' />
          )
        },
      },
      {
        Header: 'Status',
        accessor: rowObj => {
          return (
            <TableCellDropDown
              initialValue={rowObj.status}
              rowID={rowObj.item_id}
              column={'status'}
              updateMyData={updatedData}
              customField={true}
              selectionValues={PMTCONSTANTS.STATUS_SELECT}
              forName='statuses' />
          )
        },
      },
      {
        Header: 'Notes',
        accessor: rowObj => {
          return (
            <ReactTblTextAreaEditCell
              initialValue={rowObj.customField_9}
              handleShowIntModal={handleShowModal}
              rowID={rowObj.item_id}
              column={'customField_9'}
              showLinkIcon={false}
              updateMyData={updatedData}
            />
          )
        },
        className: 'intervention-col',
      }
    ]
  },
  
  //DEMAND HEALTH ------------------------------------------------------- DEMAND HEALTH -------------------------------------------- DEMAND HEALTH -------------------------------------------
  'OBJECTIVES': (app, handleShowModal, updatedData, key) => {
      let appName = app && app.fields && app.fields.appAlternateTitle ? app.fields.appAlternateTitle : app;
  
        let retArr = [
          {
            header: PMTCONSTANTS[appName].CUSTOM_KEYS.list_name,
            accessor: rowObj => {
              return (
                <EnumSelector
                  enum_type={PMTCONSTANTS.PMT_ENUMS[appName].list_name}
                  apiKey={key}
                  isMulti={false}
                  disableCreate={false}
                  value={rowObj.list_name ? rowObj.list_name : []}
                  isClearable={true}
                  fieldName={PMTCONSTANTS[appName].CUSTOM_FIELD_NAME.list_name}
                  onChange={(e) => updatedData(rowObj.item_id, 'list_name', e)}
                />
              )
            },
          },
          {
            header: PMTCONSTANTS[appName].CUSTOM_KEYS.customField_1,
            accessor: rowObj => {
              return (
                <ReactTblTextAreaEditCell
                  initialValue={rowObj.customField_1}
                  handleShowIntModal={handleShowModal}
                  rowID={rowObj.item_id}
                  column={'customField_1'}
                  showLinkIcon={false}
                  updateMyData={updatedData}
                />
              )
            },
          },
          {
            header: PMTCONSTANTS[appName].CUSTOM_KEYS.customField_2,
            accessor: rowObj => {
              return (
                <ReactTblTextAreaEditCell
                  initialValue={rowObj.customField_2}
                  rowID={rowObj.item_id}
                  column={'customField_2'}
                  updateMyData={updatedData}
                />
              )
            },
            className: 'intervention-col',
          },
          {
            header: PMTCONSTANTS[appName].CUSTOM_KEYS.customField_3,
            accessor: rowObj => {
              return (
                <EnumSelector
                  className='hmfCategory'
                  enum_type={PMTCONSTANTS.PMT_ENUMS[appName].customField_3}
                  apiKey={key}
                  isMulti={false}
                  disableCreate={false}
                  value={rowObj.customField_3 ? rowObj.customField_3 : []}
                  isClearable={true}
                  fieldName={PMTCONSTANTS[appName].CUSTOM_FIELD_NAME.customField_3}
                  onChange={(e) => updatedData(rowObj.item_id, 'customField_3', e)}
                />
              )
            },
            className: 'hmfCategory',
          },
          {
            header: 'Priority',
            accessor: rowObj => {
              return (
                <EnumSelector
                  enum_type={PMTCONSTANTS.PMT_ENUMS[appName].priority}
                  apiKey={key}
                  isMulti={false}
                  disableCreate={false}
                  value={rowObj.priority ? rowObj.priority : []}
                  isClearable={true}
                  fieldName={PMTCONSTANTS[appName].CUSTOM_FIELD_NAME.priority}
                  onChange={(e) => updatedData(rowObj.item_id, 'priority', e)}
                />
              )
            },
          },
          {
            header: 'Status',
            accessor: rowObj => {
              return (
                <EnumSelector
                enum_type={PMTCONSTANTS.PMT_ENUMS[appName].status}
                apiKey={key}
                isMulti={false}
                disableCreate={false}
                value={rowObj.status ? rowObj.status : []}
                isClearable={true}
                fieldName={PMTCONSTANTS[appName].CUSTOM_FIELD_NAME.status}
                onChange={(e) => updatedData(rowObj.item_id, 'status', e)}
              />
              )
            },
          },
          {
            header: PMTCONSTANTS[appName].CUSTOM_KEYS.customField_4,
            accessor: rowObj => {
              return (
                <ReactTblTextAreaEditCell
                  initialValue={rowObj.customField_4}
                  handleShowIntModal={handleShowModal}
                  rowID={rowObj.item_id}
                  column={'customField_4'}
                  showLinkIcon={false}
                  updateMyData={updatedData}
                />
              )
            },
          },
          {
            header: PMTCONSTANTS[appName].CUSTOM_KEYS.customField_5,
            accessor: rowObj => {
              return (
                <ReactTblTextAreaEditCell
                  initialValue={rowObj.customField_5}
                  handleShowIntModal={handleShowModal}
                  rowID={rowObj.item_id}
                  column={'customField_5'}
                  showLinkIcon={false}
                  updateMyData={updatedData}
                />
              )
            },
          },
          {
            header: 'Due Date',
            accessor: rowObj => {
              return (
                <TableDatePicerCell
                  initialValue={rowObj.due_date}
                  row={rowObj.item_id}
                  column={'due_date'}
                  updateMyData={updatedData}
                />
              )
            }
          },
          {
            header: 'Comments',
            accessor: rowObj => {
              return (
                <ReactTblTextAreaEditCell
                  initialValue={rowObj.customField_9}
                  handleShowIntModal={handleShowModal}
                  rowID={rowObj.item_id}
                  column={'customField_9'}
                  showLinkIcon={false}
                  updateMyData={updatedData}
                />
              )
            },
            className: 'intervention-col',
          }
        ]
        return retArr;
    }, 

    // -------------------------ACTIVITIES-----------------------------------------------------ACTIVITIES----------------------------------------------------ACTIVITIES------------------------------------------------------
    // 'ACTIVITIES': (app, handleShowModal, updatedData, key) => {
    //   let appName = app && app.fields && app.fields.appAlternateTitle ? app.fields.appAlternateTitle : app;
  
    //     let retArr = [
    //       {
    //         header: 'Country',
    //         accessor: rowObj => {
    //           return (
    //             <div>{rowObj.dh_country_detail.ISO}</div>
    //           )
    //         },
    //       },
    //       {
    //         header: 'Financing Status',
    //         accessor: rowObj => {
    //           return (
    //             <div>{}</div>
    //           )
    //         },
    //       },
    //       {
    //         header: '2023 Birth Cohort',
    //         accessor: rowObj => {
    //           return (
    //             <div></div>
    //           )
    //         },
    //         className: 'intervention-col',
    //       },
    //       {
    //         header: 'MR',
    //         accessor: rowObj => {
    //           return (
    //             <div></div>
    //           )
    //         },
    //         className: 'intervention-col',
    //       },
    //       {
    //         header: 'HPV',
    //         accessor: rowObj => {
    //           return (
    //             <div></div>
    //           )
    //         },
    //         className: 'intervention-col',
    //       },
    //     ]
    //     return retArr;
    // }, 
  }


export default PMTtableConfig;
import pmtutils from "./components/PMT/pmtutils";

const PMTformatUtils = {
    //On load formats how frontend expexts
    formatMarketActions: (list) => {
        if(list && list.length > 0) {            
            let retArr = list.map((listItem) => {
                return {
                    assigned_to: listItem.assigned_to ? listItem.assigned_to : '',
                    comments: listItem.comments ? listItem.comments : [],
                    created_by: listItem.created_by ? listItem.created_by : '',
                    date_closed: listItem.date_closed ? listItem.date_closed : '',
                    date_created: listItem.date_created ? listItem.date_created : '',
                    date_updated: listItem.date_updated ? listItem.date_updated : '',
                    deleted: listItem.deleted ? listItem.deleted : false,
                    description: listItem.description ? listItem.description : '',
                    start_date: listItem.start_date ? listItem.start_date : '',
                    due_date: listItem.due_date ? listItem.due_date : '',
                    item_id: listItem.intervention_id ? listItem.intervention_id : '',
                    item_name: listItem.intervention_name ? listItem.intervention_name : '',
                    list_name: listItem.market_name ? listItem.market_name : '',
                    status: listItem.status ? listItem.status : '',
                    priority: listItem.priority ? listItem.priority : '',
                    tags: listItem.tags ? listItem.tags : '',
                    customField_1: listItem.objective ? listItem.objective : '',
                    customField_2: listItem.target_outcome ? listItem.target_outcome : '',
                    customField_3: listItem.hmf_category ? pmtutils.getInitialLabelVals(listItem.hmf_category) : [],
                    customField_4: listItem.supplier ? listItem.supplier : '',
                    customField_5: listItem.stakeholder_accountable ? listItem.stakeholder_accountable : '',
                    customField_6: listItem.stakeholder_supporting ? listItem.stakeholder_supporting : '',
                    customField_7: listItem.due_date_type ? listItem.due_date_type : '',
                    customField_8: listItem.vaccine_roadmap ? listItem.vaccine_roadmap : '',
                    customField_9: listItem.notes ? listItem.notes : ''
                }
            });
            return retArr;
        }
    },

    //on update/create/delete reformats data how API expects
    deformatMarketActions: (listItem, user) => {
        let newDate = new Date();
        newDate = newDate.toISOString();

        let retArr = {
            assigned_to: listItem && listItem.assigned_to ? listItem.assigned_to : '',
            comments: listItem && listItem.comments ? listItem.comments : [],
            created_by: listItem && listItem.created_by ? listItem.created_by : user[0].displayName,
            date_closed: listItem && listItem.date_closed ? listItem.date_closed : null,
            date_created: listItem && listItem.date_created ? listItem.date_created : newDate,
            date_updated: newDate ? newDate : null,
            deleted: listItem && listItem.deleted ? listItem.deleted : false,
            description: listItem && listItem.description ? listItem.description : null,
            start_date: listItem && listItem.start_date ? listItem.start_date : null,
            due_date: listItem && listItem.due_date ? listItem.due_date : null,
            intervention_id: listItem && listItem.item_id ? listItem.item_id : null,
            intervention_name: listItem && listItem.item_name ? listItem.item_name : null,
            market_name: listItem && listItem.list_name ? listItem.list_name : '',
            status: listItem && listItem.status ? listItem.status : '',
            priority: listItem && listItem.priority ? listItem.priority : null,
            tags: listItem && listItem.tags ? listItem.tags : null,
            objective: listItem && listItem.objective ? listItem.objective : listItem && listItem.customField_1 ? listItem.customField_1 : null,
            target_outcome: listItem && listItem.target_outcome ? listItem.target_outcome : listItem && listItem.customField_2 ? listItem.customField_2 : null,
            hmf_category: listItem && listItem.hmf_category ? pmtutils.getMultiArrayObj('customField_3', listItem.hmf_category, listItem.item_id) : listItem && listItem.customField_3 ? pmtutils.getMultiArrayObj('customField_3', listItem.customField_3, listItem.item_id) : [],
            supplier: listItem && listItem.supplier ? listItem.supplier : listItem && listItem.customField_4 ? listItem.customField_4 : '',
            stakeholder_accountable: listItem && listItem.stakeholder_accountable ? listItem.stakeholder_accountable : listItem && listItem.customField_5 ? listItem.customField_5 : null,
            stakeholder_supporting: listItem && listItem.stakeholder_supporting ? listItem.stakeholder_supporting : listItem && listItem.customField_6 ? listItem.customField_6 : null,
            due_date_type: listItem && listItem.due_date_type ? listItem.due_date_type : listItem && listItem.customField_7 ? listItem.customField_7 : null,
            vaccine_roadmap: listItem && listItem.vaccine_roadmap ? listItem.vaccine_roadmap : listItem && listItem.customField_8 ? listItem.customField_8 : null,
            notes: listItem && listItem.notes ? listItem.notes : listItem && listItem.customField_9 ? listItem.customField_9 : null
        }
        return retArr;
    },

    //-------DEMAND HEALTH FORMATTER--------------------------DEMAND HEALTH FORMATTER------------------------------------------------------------------------------------------------------------------------------

    formatDemandHealth: (list) => {
        if(list && list.length > 0) {            
            let retArr = list.map((listItem) => {
                return {
                    deleted: listItem.deleted ? listItem.deleted : false,
                    due_date: listItem.due_date ? listItem.due_date : '',
                    item_id: listItem.objective_id ? listItem.objective_id : '',
                    status: listItem.status ? listItem.status : '',
                    priority: listItem.priority ? listItem.priority : '',
                    list_name: listItem.dh_antigen ? listItem.dh_antigen : '',
                    customField_1: listItem.objective ? listItem.objective : '',
                    customField_2: listItem.target_outcome ? listItem.target_outcome : '',
                    customField_3: listItem.hmf_attribute ? listItem.hmf_attribute : '',
                    customField_4: listItem.stakeholder_accountable ? listItem.stakeholder_accountable : '',
                    customField_5: listItem.stakeholder_supporting ? listItem.stakeholder_supporting : ''
                }
            });
            return retArr;
        }
    },

    //on update/create/delete reformats data how API expects
    deformatDemandHealth: (listItem) => {
        let retArr = {
            deleted: listItem && listItem.deleted ? listItem.deleted : false,
            due_date: listItem && listItem.due_date ? listItem.due_date : null,
            objective_id: listItem && listItem.item_id ? listItem.item_id : null,
            objective: listItem && listItem.customField_1 ? listItem.customField_1 : null,
            status: listItem && listItem.status && listItem.status.label ? listItem.status.label : listItem && listItem.status ? listItem.status : null,
            priority: listItem && listItem.priority && listItem.priority.label ? listItem.priority.label : listItem && listItem.priority ? listItem.priority : null,
            dh_antigen: listItem && listItem.list_name && listItem.list_name.label ? listItem.list_name.label : listItem && listItem.list_name ? listItem.list_name : '',
            target_outcome: listItem && listItem.target_outcome ? listItem.target_outcome : listItem && listItem.customField_2 ? listItem.customField_2 : null,
            hmf_attribute: listItem && listItem.customField_3 && listItem.customField_3.label ? listItem.customField_3.label : listItem && listItem.customField_3 ? listItem.customField_3 : null,
            stakeholder_accountable: listItem && listItem.stakeholder_accountable ? listItem.stakeholder_accountable : listItem && listItem.customField_4 ? listItem.customField_4 : null,
            stakeholder_supporting: listItem && listItem.stakeholder_supporting ? listItem.stakeholder_supporting : listItem && listItem.customField_5 ? listItem.customField_5 : null,
        }
        return retArr;
    },
}

export default PMTformatUtils;
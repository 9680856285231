import PMTformatUtils from "../../PMTformatUtils";
import PMTCONSTANTS from "./pmtConstants";

const pmtutils = {
    getMultiArrayObj: (field, val, id) => {
        switch(field) {
            case 'customField_3':
                let retArr = val.map((item) => {
                    let obj = {}
                    let val;
                    if (item && item.label){
                        val = item.label
                    }
                    else if(item && item[0] && item[0].label) {
                        val = item[0].label;
                    }
                    else if(item && item.hmf_category) {
                        val = item.hmf_category;
                    } 
                    else {
                        val = item;
                    }

                    obj.hmf_category = val;
                    obj.intervention_id=id;
                    return obj;
                });
                return retArr;
            default:
            break;
        }
    },
    //Will add more cases as add apps
    //finds the correct formater to send the form data to to reformat to what is expected in the backend
    sendToFormatter: (app, formItem, user) => {
        let infoToSend;
        switch (app.fields.appAlternateTitle) {
            case "PMT MA":
                infoToSend = PMTformatUtils.deformatMarketActions(formItem, user);
                return infoToSend;
            case "Demand Health":
                infoToSend = PMTformatUtils.deformatDemandHealth(formItem, user);
                return infoToSend;
            default:
             break;
        }
    },

    //Alphabetizes toplist
    alphabetizedList: (list) => {
        let alphaList = list.sort((a, b) => a.list_name.localeCompare(b.list_name));
        return alphaList;
    },

    //Formats the data to custom_fields based on app
    formatDataBasedOnApp: (list, dataType) => {
        if( list && list.length > 0 ) {
            if (dataType === 'OBJECTIVES') {
                let retArr = PMTformatUtils.formatDemandHealth(list)
                    pmtutils.alphabetizedList(retArr)
                return retArr;
            }
        } else {
            pmtutils.alphabetizedList(list)
        }
    },
        
    getInitialLabelVals: (fieldArr) => {
        let retArr = [];
        if (fieldArr && fieldArr.length > 0) {
            fieldArr.forEach((item) => { 
                if(item && item.hmf_category) {
                    retArr.push(item.hmf_category)
                }
                if(item && item.label) {
                    retArr.push(item.label)
                }
            })
        }
        return retArr;
    }, 

    updateListItem: async (item, app, apiKey, addToast) => {
        try {
            const result = await fetch(`${process.env.REACT_APP_DB_API_URL}${PMTCONSTANTS[app].UPSERT_ITEM}?${PMTCONSTANTS[app].ID_PARAM}=${item[PMTCONSTANTS[app].ID]}`, {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json',
                'access_token': apiKey
                //need to add other access token for workspace access here
              },
              body: JSON.stringify(item)
            })
            if (result['status'] === 403) {
              addToast({
                title: 'isValid Key',
                body: "Invalid API key, access denied"})
            }
          } catch (error) {
            addToast({
              title: 'Update Error',
              body: 'Whoops!  something happened on this submit.  Please try again.'
            })
         }
    }
}

export default pmtutils;
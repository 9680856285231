import React from 'react';
import { Row, Col } from 'react-bootstrap';
import PMTtableConfig from './PMTtableConfig';

function PMTTable({tableHeaderConfig, appName, formattedList, handleShowModal, handleListUpdate, apiKey, configType}) {
  return (
    <Row>
        <Col className='table-column' md={12}>
            <div className='table-div'>
                <table>
                    <thead>
                        <tr>
                            {
                                   tableHeaderConfig.map((column, idx) => (
                                    <th key={idx}>{column.header}</th>
                                ))}
                        </tr>
                    </thead>
                    <tbody>
                        {
                            formattedList.map((item, idx) => {
                                return (
                                    <tr key={idx}>
                                        {
                                            PMTtableConfig[configType](appName, handleShowModal, handleListUpdate, apiKey).map((cell, idx) => {
                                                return (
                                                    <td key={idx}>{cell.accessor(item)}</td>
                                                )
                                            })
                                        }
                                    </tr>
                                )
                            })
                        }
                    </tbody>
                </table>
            </div>
        </Col>
    </Row>
  )
}

export default PMTTable
import React, { useMemo } from 'react';
import { useTable, useRowSelect, useResizeColumns, useFlexLayout } from 'react-table';
import { Row, Col } from 'react-bootstrap';
import PMTtableConfig from './PMTtableConfig';
import PMTCONSTANTS from '../pmtConstants';
import utils from '../../../utils';
import ListSkeletonLoader from '../../Layout/ListSkeletonLoader';
// import IndeterminateCheckbox from '../IndeterminateCheckbox';

function PMTTableView({ formattedList, handleShowItemModal, onUpdate, appName, suppliers=[], assigneesToPass, tenant, apiKey }) {
    let supplierList = suppliers && suppliers.length > 0 ? utils.getOptions(suppliers) : null;

    const updatedData = (id, fieldName, value) => {
        //updates for enumselectors and fields like hmf_category
        if(value && Array.isArray(value) && value.length > 0) {
            let valsToSend = value.map((val) => {return val.label})
            onUpdate({ id: id, field: fieldName, value: valsToSend});

        } else {
            onUpdate({ id: id, field: fieldName, value: value });
        }
    }

    //Gets the table config for the specified app
    const getColumnHeaders = (app, handleShowItemModal, updatedData, suppliers=[], assignees=[], priority, tenant, apiKey) => {
        switch(app) {

            //MARKET ACTIONS
            case 'PMT MA':
                return PMTtableConfig.getMarketActionsTableConfig(app, handleShowItemModal, updatedData, suppliers, assignees, priority, tenant, apiKey)
            case 'Demand Health':
                return PMTtableConfig.getDemandHealthTableConfig(app, handleShowItemModal, updatedData, suppliers, assignees, priority, tenant, apiKey)
            default:
                break;
        }
    }

    const columns = useMemo(
        () => getColumnHeaders(appName.fields.appAlternateTitle, handleShowItemModal, updatedData, supplierList, assigneesToPass, PMTCONSTANTS.PRIORITY_SELECT, tenant, apiKey),
        // eslint-disable-next-line
        []
    )

    const defaultColumn = useMemo(
        () => ({
            width: 220,
            minWidth: 41,
            maxWidth: 1000
        }),
        []
    );

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow,
    } = useTable({
        columns,
        data: formattedList,
        defaultColumn
    },
        useResizeColumns,
        useFlexLayout,
        useRowSelect,
    )



    return (
        formattedList ?
            <Row>
                <Col className='table-column' md={12}>
                    <div className='table-div'>
                        <table {...getTableProps()}>
                            <thead>
                                {
                                    headerGroups.map((headerGroup, index) => (
                                        <tr {...headerGroup.getHeaderGroupProps()} key={index}>
                                            {
                                                headerGroup.headers.map((column, index) => (
                                                    <th {...column.getHeaderProps({
                                                        className: column.className
                                                    }
                                                    )}
                                                        key={index}
                                                    >
                                                        {column.render('Header')}
                                                        {column.canResize && (
                                                            <div{...column.getResizerProps()}
                                                                className={`resizer ${column.resizing ? 'isResizing' : ''}`}
                                                            />
                                                        )}
                                                    </th>
                                                ))}
                                        </tr>
                                    ))}
                            </thead>
                            <tbody {...getTableBodyProps()}>
                                {
                                    rows.map((row, index) => {
                                        prepareRow(row)
                                        return (
                                            <tr {...row.getRowProps()} key={index}>
                                                {
                                                    row.cells.map((cell, index) => {
                                                        return (
                                                            <td {...cell.getCellProps({
                                                                className: cell.column.className
                                                            })} key={index}>
                                                                {cell.render('Cell')}
                                                            </td>
                                                        )
                                                    })
                                                }
                                            </tr>
                                        )
                                    })
                                }
                            </tbody>
                        </table>


                        {/* <p className='selected-rows'>Selected Rows: {Object.keys(selectedRowIds).length}</p>
                        <pre>
                            <code>
                                {JSON.stringify(
                                    {
                                        selectedRowIds: selectedRowIds,
                                        'selectedFlatRows[].original': selectedFlatRows.map(
                                            d => d.original
                                        ),
                                    },
                                    null,
                                    2
                                )}
                            </code>
                        </pre> */}
                    </div>
                </Col>
            </Row>
            : <ListSkeletonLoader preview={false} />
    )
}

export default PMTTableView